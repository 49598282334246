<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="협력사 협의체 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="tabParam.councilId && editable" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="tabParam"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  required
                  :editable="editable"
                  label="제목"
                  name="councilTitle"
                  v-model="tabParam.councilTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  label="일자"
                  name="councilDate"
                  v-model="tabParam.councilDate">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  required
                  type="edit"
                  codeGroupCd="COUNCIL_TYPE_CD"
                  itemText="codeName"
                  itemValue="code"
                  name="councilTypeCd"
                  label="구분"
                  v-model="tabParam.councilTypeCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="tabParam.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <c-textarea
                  :editable="editable"
                  :rows="4"
                  label="내용"
                  name="councilContents"
                  v-model="tabParam.councilContents">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="inTable"
              title="내부참석자 목록"
              :columns="inGrid.columns"
              gridHeight="250px"
              :data="tabParam.attendeeInModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :hideBottom="true"
              :editable="editable"
              :checkClickFlag="false"
              :isExcelDown="false"
              rowKey="councilAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                  <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addInAttendee" />
                  <c-btn v-if="editable && tabParam.attendeeInModels.length > 0" :showLoading="false"  label="제외" icon="remove" @btnClicked="removeInAttendee" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-card title="협의체 관련 첨부파일" class="cardClassDetailForm">
              <template slot="card-detail">
                <div class="col-sm-12 col-md-12 col-lg-12">
                  <c-upload 
                    :attachInfo="attachInfo"
                    :editable="editable"
                    label="협의체 관련 첨부파일">
                  </c-upload>
                </div>
              </template>
            </c-card>
          </div>
        </div>  
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="vendorTable"
              title="대상 협력업체"
              :columns="outVendorGrid.columns"
              gridHeight="250px"
              :data="tabParam.coucilVendorModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :hideBottom="true"
              :checkClickFlag="false"
              :isExcelDown="false"
              :editable="editable"
              rowKey="vendorCd"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                  <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addVendor" />
                  <c-btn v-if="editable && tabParam.coucilVendorModels.length > 0" :showLoading="false"  label="제외" icon="remove" @btnClicked="removeVendor" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-table
              ref="outTable"
              title="외부참석자 목록"
              :columns="outGrid.columns"
              gridHeight="250px"
              :data="tabParam.attendeeOutModels"
              :filtering="false"
              :columnSetting="false"
              :usePaging="false"
              :hideBottom="true"
              :checkClickFlag="false"
              :isExcelDown="false"
              :editable="editable"
              rowKey="councilAttendeeId"
              selection="multiple"
            >
              <template slot="table-button">
                <q-btn-group outline>
                  <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="addOutAttendee" />
                  <c-btn v-if="editable && tabParam.attendeeOutModels.length > 0" :showLoading="false"  label="제외" icon="remove" @btnClicked="removeOutAttendee" />
                </q-btn-group>
              </template>
            </c-table>
          </div>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'council-detail',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        councilId: '',
        plantCd: '',
        councilDate: '',
        councilContents: '',
        councilTitle: '',
        councilTypeCd: null,
        attendeeInModels: [],
        attendeeOutModels: [],
        deleteAttendeeInModels: [],
        deleteAttendeeOutModels: [],
        coucilVendorModels: [],
        deletecoucilVendorModels: [],

        regUserId: '',
        chgUserId: '',
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'SOP_COUNCIL',
        taskKey: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      attachable: false,
      saveUrl: transactionConfig.mdm.cim.vendor.council.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      updateMode:false,
      realKey: '',
      inGrid: {
        columns: [
          {
            name: 'attendeeDeptName',
            field: 'attendeeDeptName',
            label: '부서',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            label: '이름',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            label: '직책',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
        data: [],
      },
      outVendorGrid: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '협력업체',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'left',
            style: 'width: 60%',
          },
        ],
        data: [],
      },
      outGrid: {
        columns: [
          {
            name: 'attendeeBelong',
            field: 'attendeeBelong',
            label: '소속',
            type: 'text',
            align: 'center',
            style: 'width: 80px',
            required: true,
          },
          {
            name: 'attendeeUserName',
            field: 'attendeeUserName',
            label: '이름',
            style: 'width: 80px',
            type: 'text',
            required: true,
            align: 'center',
          },
          {
            name: 'attendeeJobName',
            field: 'attendeeJobName',
            label: '직책',
            type: 'text',
            style: 'width: 80px',
            align: 'center',
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            align: 'left',
            style: 'width: 200px',
          },
        ],
        data: [],
      },
      saveCallData: '',
    };
  },
  computed: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      // url setting
      this.insertUrl = transactionConfig.mdm.cim.vendor.council.insert.url;
      this.updateUrl = transactionConfig.mdm.cim.vendor.council.update.url;
      this.deleteUrl = transactionConfig.mdm.cim.vendor.council.delete.url;
    },
    saveData() {
      if (this.tabParam.councilId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.outGrid.columns, this.tabParam.attendeeOutModels)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.tabParam.regUserId = this.$store.getters.user.userId;
                this.tabParam.chgUserId = this.$store.getters.user.userId;

                this.isSave = !this.isSave;
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.councilId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.tabParam.councilId = result.data
      this.$set(this.attachInfo, 'taskKey', this.tabParam.councilId)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.saveCallData = uid();
      this.$emit('changeStatus', result.data)
    },
    addInAttendee() {
      this.popupOptions.title = "내부참석자 검색"; 
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeUserPopup;

    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.attendeeInModels, {
            attendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.tabParam.attendeeInModels.splice(0, 0, {
              councilId: this.tabParam.councilId,
              councilAttendeeId: uid(),
              attendeeType: '1',
              attendeeUserId: _item.userId,
              attendeeUserName: _item.userName,
              attendeeDeptName: _item.deptName,
              attendeeJobName: _item.jobName,
              remark: '',
              chgUserId: this.$store.getters.user.userId,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeInAttendee() {
      let selectData = this.$refs['inTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deleteAttendeeInModels) {
            this.tabParam.deleteAttendeeInModels = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAttendeeInModels, { councilAttendeeId: item.councilAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.tabParam.deleteAttendeeInModels.push(item)
          }
          this.tabParam.attendeeInModels = this.$_.reject(this.tabParam.attendeeInModels, item)
        })
      }
    },
    addOutAttendee() {
      this.tabParam.attendeeOutModels.splice(0, 0, {
        councilId: this.tabParam.councilId,
        councilAttendeeId: uid(),
        attendeeType: '2',
        attendeeUserId: '',
        attendeeUserName: '',
        attendeeJobName: '',
        remark: '',
        attendeeBelong: '',
        chgUserId: this.$store.getters.user.userId,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeOutAttendee() {
      let selectData = this.$refs['outTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deleteAttendeeOutModels) {
            this.tabParam.deleteAttendeeOutModels = []
          }
          if (this.$_.findIndex(this.tabParam.deleteAttendeeOutModels, { councilAttendeeId: item.councilAttendeeId }) === -1
            && item.editFlag !== 'C') {
              this.tabParam.deleteAttendeeOutModels.push(item)
          }
          this.tabParam.attendeeOutModels = this.$_.reject(this.tabParam.attendeeOutModels, item)
        })
      }
    },
    addVendor() {
      this.popupOptions.title = "협력업체 검색";
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeVendorPopup;

    },
    closeVendorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tabParam.coucilVendorModels, {
            vendorCd: _item.vendorCd,
          });
          if (index === -1) {
            this.tabParam.coucilVendorModels.push({
              councilId: this.tabParam.councilId ? this.tabParam.councilId : uid(),
              vendorCd: _item.vendorCd,
              vendorName: _item.vendorName,
              remark: '',
              chgUserId: this.$store.getters.user.userId,
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeVendor() {
      let selectData = this.$refs['vendorTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.tabParam.deletecoucilVendorModels) {
            this.tabParam.deletecoucilVendorModels = []
          }
          if (this.$_.findIndex(this.tabParam.deletecoucilVendorModels, { vendorCd: item.vendorCd }) === -1
            && item.editFlag !== 'C') {
              this.tabParam.deletecoucilVendorModels.push(item)
          }
          this.tabParam.coucilVendorModels = this.$_.reject(this.tabParam.coucilVendorModels, item)
        })
      }
    },
  }
};
</script>
